<template>
  <div class="ts-zuimei">
    <div class="ts-zuimei-content">
      <!-- 第一屏 -->
      <div class="zm-bg1">
        <div class="zm-bg1-authority">
          <a @click="showAuthorityBox">权限</a>&nbsp;|&nbsp;<a
            href="https://oss.tushuo.info/idphoto/web/private.html"
            >协议</a
          >
        </div>
        <div class="zm-bg1-title"></div>
        <div class="zm-bg-mintitle">拍证件照像自拍一样简单又好看</div>
        <div class="zm-bg1-gif"></div>
        <div class="zm-bg1-bottom">
          <div class="zm-bg1-list">
            <div class="list-name">
              <img
                src="../../../static/imgs/zuimei/ic_gaoqing.png"
                alt=""
              />高清证件照
            </div>
            <div class="list-name">
              <img
                src="../../../static/imgs/zuimei/ic_meiyan.png"
                alt=""
              />自然美颜
            </div>
            <div class="list-name">
              <img
                src="../../../static/imgs/zuimei/ic_huandi.png"
                alt=""
              />证件照换底
            </div>
            <div class="list-name">
              <img
                src="../../../static/imgs/zuimei/ic_mianfei.png"
                alt=""
              />免费修改
            </div>
          </div>
          <div class="zm-bg1-btn" @click="download">免费下载使用</div>
          <div class="zm-bg1-txt">
            【V1.2.8·软件大小 21.6MB】&nbsp;&nbsp; 开发者：苏州图说科技有限公司
          </div>
        </div>
      </div>
      <!-- 第二屏 -->
      <div class="zm-bg2">
        <div class="zm-bg-title"><span>高清最美证件照</span>值得信赖</div>
        <div class="zm-bg2-tip">行业领先算法，确保证件照更合格，更好看</div>

        <div class="zm-bg2-main">
          <div class="bg2-main-title">
            <div class="bg2-main-left">
              <span>高清最美证件照</span>
            </div>
            <div class="bg2-main-right">
              <span>其他证件照软件</span>
            </div>
          </div>
          <div class="zm-bg2-list pic">
            <p><img src="../../../static/imgs/zuimei/pic_blue.png" alt="" /></p>
            <p>
              <img src="../../../static/imgs/zuimei/pic_blue_qita.png" alt="" />
            </p>
          </div>
          <div class="zm-bg2-list">
            <p><b>人工智能美颜算法</b><br />不假面，妆容更自然</p>
            <p>
              <img src="../../../static/imgs/zuimei/ic_cha.png" alt="" /><b
                >传统美颜算法</b
              ><br />美颜僵硬，不真实
            </p>
          </div>
          <div class="zm-bg2-list">
            <p><b>独家高清人像技术</b><br />五官立体，相馆级肤质</p>
            <p class="pt">
              <img src="../../../static/imgs/zuimei/ic_cha.png" alt="" /><b
                >无此功能</b
              >
            </p>
          </div>
          <div class="zm-bg2-list">
            <p>
              <b>免费修改，确保合格</b><br />可修改文件大小/dpi/重命名/ 添加文
            </p>
            <p class="pt">
              <img src="../../../static/imgs/zuimei/ic_cha.png" alt="" /><b
                >无此功能</b
              >
            </p>
          </div>
          <div class="zm-bg2-list">
            <p><b>售后完善</b><br />在线/微信/电话等多端客服 全天侯答疑</p>
            <p class="pt">
              <img src="../../../static/imgs/zuimei/ic_cha.png" alt="" /><b
                >无客服或处理不及时</b
              >
            </p>
          </div>
        </div>
      </div>
      <!-- 第三屏 -->
      <div class="zm-bg3">
        <div class="zm-bg-title">只需3步，轻松自拍证件照</div>
        <div class="zm-bg-tip">AI智能抠图，精准到发丝，背景自然融合</div>
        <div class="zm-bg3-box">
          <div class="zm-bg3-step">
            <div class="step-list">
              <img src="../../../static/imgs/zuimei/ic_step1.png" alt="" />
              <p>上传照片</p>
              <span>step1</span>
            </div>
            <div class="step-list">
              <img src="../../../static/imgs/zuimei/ic_step2.png" alt="" />
              <p>选择底色，美颜/清晰度</p>
              <span>step2</span>
            </div>
            <div class="step-list">
              <img src="../../../static/imgs/zuimei/ic_step3.png" alt="" />
              <p>保存至相册</p>
              <span>step3</span>
            </div>
          </div>
          <div class="zm-bg3-pic">
            <img src="../../../static/imgs/zuimei/pic_3.png" alt="" />
          </div>
        </div>
      </div>
      <!-- 第四屏 -->
      <div class="zm-bg4">
        <div class="zm-bg-title">独家<span>人脸高清</span>技术</div>
        <div class="zm-bg-mintitle">超清晰，不假面，打造相馆级质感证件照</div>
        <div class="zm-bg4-list">
          <div class="list-txt">
            <p>增强五官立体感</p>
            <span>让面部更精致美观</span>
          </div>
          <div class="list-txt">
            <p>明亮双眼细节</p>
            <span>细节清晰可见</span>
          </div>
          <div class="list-txt">
            <p>肤质优化</p>
            <span>高级柔焦磨皮</span>
          </div>
          <div class="list-txt">
            <p>相馆级质感</p>
            <span>清晰不假面</span>
          </div>
        </div>
      </div>
      <!-- 第五屏 -->
      <div class="zm-bg5">
        <div class="zm-bg-title">支持所有证件照尺寸</div>
        <div class="zm-bg-mintitle">电子照直接保存 纸质版全国包邮</div>
        <div class="zm-bg5-pic">
          <img src="../../../static/imgs/zuimei/pic_5.png" alt="" />
        </div>
      </div>
      <!-- 第六屏 -->
      <div class="zm-bg6">
        <div class="zm-bg-title"><span>免费无限次修改</span></div>
        <div class="zm-bg-mintitle">文件大小、dpi、重命名、添加文字</div>
        <div class="zm-bg6-pic">
          <img src="../../../static/imgs/zuimei/pic_6.png" alt="" />
        </div>
        <div class="zm-bg6-details">
          <div class="zm-bg6-txt">
            <div class="bg6-details-title old"><span>原照片参数</span></div>
            <div class="zm-bg6-list">像素大小：295×413px</div>
            <div class="zm-bg6-list">分 辨 率 ：300dpi</div>
            <div class="zm-bg6-list">文件大小：200kb</div>
            <div class="zm-bg6-list">文件名称：2010101.jpg</div>
          </div>
          <div class="zm-bg6-txt">
            <div class="bg6-details-title new"><span>修改后照片参数</span></div>
            <div class="zm-bg6-list">像素大小：<span>295×413px</span></div>
            <div class="zm-bg6-list">分 辨 率 ：<span>300dpi</span></div>
            <div class="zm-bg6-list">文件大小：<span>200kb</span></div>
            <div class="zm-bg6-list">
              文件名称：<span>李四20101012.jpg</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 第七屏 -->
      <div class="zm-bg7">
        <div class="zm-bg-title">7*24小时客服在线答疑</div>
        <div class="zm-bg-mintitle">
          客服响应快，效率高，为您的证件照保驾护航
        </div>
        <div class="zm-bg7-pic">
          <img src="../../../static/imgs/zuimei/pic_7.png" alt="" />
        </div>
        <div class="bg7-evaluate">
          <div class="bg7-evaluate-title">真实用户评价</div>
          <div class="bg7-evaluate-tip">
            已有<span>25391</span>个用户给出好评
          </div>
          <div class="bg7-score">
            <div class="bg7-details">
              <div class="bg7-details-name">
                <p>4.9分</p>
                <span>综合评分</span>
              </div>
            </div>
          </div>
          <div class="bg7-sliders">
            <swiper ref="evalSwiper" :options="evalSwiperOptions">
              <swiper-slide>
                <div class="bg7-sliders-details">
                  <div class="bg7-slider-name">
                    <p>
                      <img
                        src="https://oss.puwu.info/public/web/header/1.jpg"
                        alt=""
                      />
                    </p>
                    最美***
                  </div>
                  <div class="bg7-slider-txt">
                    作为多年证件照老用户，真心觉得证件照制作是用心做产品的app，精细打磨的效果，努力开辟新功能，都很惊喜。希望越来越好。点赞
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="bg7-sliders-details">
                  <div class="bg7-slider-name">
                    <p>
                      <img
                        src="https://oss.puwu.info/public/web/header/2.jpg"
                        alt=""
                      />
                    </p>
                    可爱的***
                  </div>
                  <div class="bg7-slider-txt">
                    证件照软件很好用，一键换背景超级满意 
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="bg7-sliders-details">
                  <div class="bg7-slider-name">
                    <p>
                      <img
                        src="https://oss.puwu.info/public/web/header/3.jpg"
                        alt=""
                      />
                    </p>
                    胖三***
                  </div>
                  <div class="bg7-slider-txt">
                    软件功能丰富，有不同的照片尺寸，还能换正装
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="bg7-sliders-details">
                  <div class="bg7-slider-name">
                    <p>
                      <img
                        src="https://oss.puwu.info/public/web/header/4.jpg"
                        alt=""
                      />
                    </p>
                    你的***
                  </div>
                  <div class="bg7-slider-txt">
                    拍了一张100KB太大,然后可以免费改成50KB,赞赞赞
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="bg7-sliders-details">
                  <div class="bg7-slider-name">
                    <p>
                      <img
                        src="https://oss.puwu.info/public/web/header/5.jpg"
                        alt=""
                      />
                    </p>
                    红衣少***
                  </div>
                  <div class="bg7-slider-txt">
                    这个比别的拍的清晰，还有美颜，喜欢！
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="bg7-sliders-details">
                  <div class="bg7-slider-name">
                    <p>
                      <img
                        src="https://oss.puwu.info/public/web/header/6.jpg"
                        alt=""
                      />
                    </p>
                    帅萌的***
                  </div>
                  <div class="bg7-slider-txt">
                    疫情复工公司同事推荐用来拍通行证照片的，拍得证件照不错呐!
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="bg7-sliders-details">
                  <div class="bg7-slider-name">
                    <p>
                      <img
                        src="https://oss.puwu.info/public/web/header/7.jpg"
                        alt=""
                      />
                    </p>
                    王七的***
                  </div>
                  <div class="bg7-slider-txt">
                    证件照制作效果也很自然，好评！
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
        <div class="zm-footer-page">
          <p>
            开发者：苏州图说科技有限公司
            <a href="http://www.beian.miit.gov.cn" target="_blank"
              >苏ICP备20046747号-2</a
            >
          </p>
          <p>
            高清最美证件照 V1.2.8
            <a class="blue" @click="showAuthorityBox">应用权限</a>&nbsp;<a
              class="blue"
              href="https://oss.tushuo.info/idphoto/web/private.html"
              >隐私协议</a
            >
          </p>
          <!-- <p>
              地址：中国（江苏）自由贸易试验区苏州片区苏州工业园区裕新路168号脉山龙大厦一号楼420室
            </p>
            <p>邮箱：feedback@techpuwu.com &nbsp; 电话：0512-87816685</p> -->
        </div>
      </div>
      <!-- 悬浮按钮 -->
      <div class="zm-btn" @click="download">免费下载使用</div>
    </div>

    <common-dialog
      :showDialog="isShowDialog"
      :dialogTitle="dialogTitle"
      :dialogContent="dialogContent"
      :showSingleBtn="showSingleBtn"
      :isAlignLeft="isAlignLeft"
      @rightBtnFn="showDialog"
    >
    </common-dialog>
  </div>
</template>
<script>
import commonDialog from "../../../component/dialog/dialog.vue";
export default {
  name: "yinxiang",
  components: {
    commonDialog,
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  data() {
    let that = this;
    return {
      isShowDialog: false,
      dialogTitle: "获取权限说明",
      showSingleBtn: true,
      isAlignLeft: true,
      dialogContent: "",

      yxUrl: {},
      isIOS: false,

      evalSwiperOptions: {
        autoplay: {
          delay: 3000,
        },
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: "auto",
        coverflowEffect: {
          rotate: 30, // 角度调整
          stretch: 0,
          depth: 200,
          modifier: 1,
          slideShadows: false, //是否开启阴影
        },
      },
    };
  },
  mounted() {
    document.title = "高清最美证件照";

    this.zmUrl = {
      ios: "https://apps.apple.com/cn/app/id1535425796",
      android: "https://oss.tushuo.info/idphoto/upload/高清最美证件照.apk",
    };
    let ua = navigator.userAgent.toLowerCase();
    this.isIOS = ua.indexOf("mac os") > -1;

    // let ua = navigator.userAgent.toLowerCase();
    // let flag = /mobile|android|iphone|ipad|phone/i.test(ua);
    // if (!flag) {
    //   this.$router
    //     .push({
    //       name: "yinxiang",
    //     })
    //     .catch((err) => {
    //       err;
    //     });
    // }

    // this.slideTo(6);
  },
  methods: {
    slideTo(index) {
      this.swiper.slideTo(index, 1000, false);
      this.imgIndex = index;
    },
    download() {
      location.href = this.isIOS ? this.zmUrl.ios : this.zmUrl.android;
    },
    showAuthorityBox() {
      this.isShowDialog = true;
      this.dialogContent =
        "为给您提供更优质的服务，高清最美证件照App 需要向您申请以下权限：<br/>1. 相册读写权限： 用于您上传照片制作证件照；<br/>2. 相机权限：用于拍摄照片制作证件照；<br/>3. 存储权限： 用于保存制作好的证件照；<br/>4. 推送通知权限：用于接收客服回复的消息；<br/>5. 语音输入权限：用于语音咨询客服问题。";
    },
    showDialog() {
      this.isShowDialog = false;
    },
  },
};
</script>
<style lang="less">
// @import "../../../styles/idphoto/app.less";
@import "./m_zuimei.less";
</style>