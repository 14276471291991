<template>
  <div class="m-tsvideo-wrap">
    <div class="tsvideo-top-wrap">
      <div class="tsvideo-banner">
        <div class="m-banner-logo"></div>
        <div class="m-banner-txt"></div>
      </div>
      <div class="m-tsvideo-top">
        <div class="m-tsvideo-txt">深度全面扫描 一键恢复</div>
        <!-- <div class="m-tsvideo-btn">立即下载</div> -->
      </div>
    </div>
    <div class="m-tsvideo-pros">
      <div class="m-tsvideo-title">主要产品</div>
      <div class="m-pros-main">
        <div class="m-pros-list">
          <img src="../../../static/imgs/video/ic_zhaopianhuifu.png" alt />照片恢复
        </div>
        <div class="m-pros-list">
          <img src="../../../static/imgs/video/ic_shipinhuifu.png" alt />视频恢复
        </div>
        <div class="m-pros-list">
          <img src="../../../static/imgs/video/ic_yinpinhuifu.png" alt />音频恢复
        </div>
        <div class="m-pros-list">
          <img src="../../../static/imgs/video/ic_wenjianhuifu.png" alt />文档恢复
        </div>
      </div>
    </div>
    <div class="m-tsvideo-step">
      <div class="m-tsvideo-title">
        操作简单
        <br />找回仅需三步
      </div>
      <div class="step-list-wrap">
        <div class="step-list one">
          <div>Step1&nbsp;&nbsp;扫描手机文件</div>
        </div>
        <div class="step-list two">
          <div>Step2&nbsp;&nbsp;预览文件大图</div>
        </div>
        <div class="step-list three">
          <div>Step3&nbsp;&nbsp;保存此文件到相册</div>
        </div>
      </div>
    </div>
    <div class="m-tsvideo-advantage">
      <div class="m-tsvideo-title">我们的优势</div>
      <div class="advantage-main">
        <div class="list-title one">简单操作</div>
        <div class="list-txt">3步即可恢复完成</div>
        <div class="list-title two">免费扫描预览</div>
        <div class="list-txt">免费极速扫描，深度挖掘</div>
        <div class="list-title three">保护隐私 安全可靠</div>
        <div class="list-txt">本机操作，恢复全过程本平台不会窥探保存任</div>
        <div class="list-title four">专属客服对接 助力恢复</div>
        <div class="list-txt">专业人工客服在线解答恢复过程中所有问题</div>
      </div>
    </div>
    <div class="tsvideo-evaluation">
      <div class="m-tsvideo-title">用户评价</div>
      <div class="evaluation-list-wrap">
        <swiper ref="mySwiper" :options="swiperOptions">
          <swiper-slide>
            <div class="evaluation-list">
              <div class="slider-pic">
                <img src="../../../static/imgs/video/vr-header1.png" alt />
              </div>
              <div class="name">小青***</div>
              <div class="txt">照片不小心删除了。我搜到这个 软件试了，找到我删除的照片了， 这对我来说非常的重要。我感谢 这个软件帮我恢复了删除的照片。</div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="evaluation-list">
              <div class="slider-pic">
                <img src="../../../static/imgs/video/vr-header2.png" alt />
              </div>
              <div class="name">兔子***</div>
              <div class="txt">挺好的，先免费预览恢复的记录， 然后再付钱的，比那些啥都没见 着就要先付钱的靠谱多了。</div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="evaluation-list">
              <div class="slider-pic">
                <img src="../../../static/imgs/video/vr-header3.png" alt />
              </div>
              <div class="name">时光***</div>
              <div class="txt">关键的照片部分都成功恢复了，还 有些视频内容还没有能打开，工作 人员协助下，也找回了。</div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <!-- <div class="m-tsvideo-load">安全下载</div> -->
    </div>
    <div class="tsvideo-footer">
      联系电话：0512-87816685
      <br />联系邮箱：feedback@techtushuo.com
      <br />联系地址：中国（江苏）自由贸易试验区苏州片区苏州工业园区裕新路168号脉山龙大厦一号楼420室
      <br />Copyright@2020苏州图说科技有限公司
    </div>
  </div>
</template>
<script>
export default {
  name: "ts_idphoto",
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  data() {
    return {
      swiperOptions: {
        autoplay: {
          delay: 3000,
        },
        effect: "coverflow",
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: "auto",
        coverflowEffect: {
          rotate: 0, // 角度调整
          stretch: 0,
          depth: 200,
          modifier: 1,
          slideShadows: false, //是否开启阴影
        },
      },
    };
  },
  mounted() {
    let ua = navigator.userAgent.toLowerCase();
    if (!/mobile|android|iphone|ipad|phone/i.test(ua)) {
      this.$router
        .push({
          name: "ai_video_restore",
        })
        .catch((err) => {
          err;
        });
    }
  },
  methods: {
    anzhuoDown() {
      window.open(
        "https://a.app.qq.com/o/simple.jsp?pkgname=com.puwu.magiclogo"
      );
    },
  },
};
</script>
<style lang="less" scoped>
@import "./m_video_restore.less";
</style>