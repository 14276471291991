<template>
  <div class="tushuo-wrap">


    <div class="hy-banner">
      <div class="hy-banner-left">
        <div class="hy-logo"></div>
        <div class="hy-txt">图说科技</div>
      </div>
    </div>

    <div class="hy-pro">
      <div class="hy-pro-title">公司简介</div>
      <div class="hy-pro-txt">
        苏州图说科技有限公司是一家高新技术企业，主要致力于图像处理，人工智能方向研究。针对生活场景，开发了高清最美证件照，老照片修复，照片恢复等软件。
        高清最美证件照方便用户随时随地拍摄制作出标准证件照；老照片修复主要解决用户破损照片秒翻新；照片恢复主要帮助用户快速找回误删照片视频等。
        未来，图说科技将会快速优化产品，推出更多优秀的图像处理产品。来帮助更多的用户解决生活中遇到的图像处理问题。
      </div>
    </div>

    <!-- main start -->
    <div class="rephoto-top-wrap" @click="goToRephoto">
      <div class="rephoto-top">
        <div class="rephoto-top-pic"></div>
        <div class="rephoto-top-txt">
          <div class="rephoto-top-title">照片修复APP</div>
          <div class="rephoto-pros">
            <div class="rephoto-pros-list">旧照片修复</div>
            <div class="rephoto-pros-list">照片修补</div>
            <div class="rephoto-pros-list">黑白照上色</div>
            <div class="rephoto-pros-list">风格转换</div>
          </div>
        </div>
      </div>
    </div>

    <div class="hy-main" @click="gotoRestore">
      <div class="hy-list">
        <div class="hy-main-text">
          <div class="hy-main-title">照片视频恢复APP</div>
          <div class="hy-main-txt">照片/视频/音频/文件 一键恢复</div>
        </div>
        <div class="tsvideo-top-pic"></div>
      </div>
    </div>

    <div class="hy-top-wrap" @click="gotoIdphoto">
      <div class="hy-top">
        <div class="hy-top-left"></div>
        <div class="hy-top-main">
          <div class="hy-top-title">高清最美证件照APP</div>
          <div class="hy-top-txt">让拍证件照像自拍一样简单又好看</div>
          <div class="hy-top-load">
            <!-- <div class="hy-top-qrcode"></div> -->
            <!-- <div class="hy-top-btns">
              <div class="hy-top-btn ios">iOS下载</div>
              <div class="hy-top-btn">安卓下载</div>
            </div>-->
          </div>
        </div>
      </div>
    </div>

    <!-- main end -->

    <div class="tushuo-footer">
      <div class="copyright">
        Copyright@2020苏州图说科技有限公司
        <a href="http://beian.miit.gov.cn" target="_blank"
          >苏ICP备20046747号-1</a
        >
      </div>
      <div class="addr">
        地址：中国（江苏）自由贸易试验区苏州片区苏州工业园区裕新路168号脉山龙大厦一号楼420室
      </div>
      <div class="contact">
        电话：0512-87816685 &nbsp;&nbsp;&nbsp;&nbsp;
        邮箱：feedback@techtushuo.com
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ts_idphoto",
  data() {
    return {};
  },
  mounted() {
    let ua = navigator.userAgent.toLowerCase();
    if (/mobile|android|iphone|ipad|phone/i.test(ua)) {
      this.$router
        .push({
          name: "measy_idphoto",
        })
        .catch((err) => {
          err;
        });
    }
  },
  methods: {
    goToRephoto() {
      window.open(
        "http://www.techtushuo.com/ts_rephoto.html",'_blank'
      );
    },
    gotoRestore(){
      window.open(
        "http://www.techtushuo.com/ai_video_restore.html",'_blank'
      );
    },
    gotoIdphoto(){
      window.open(
        "http://www.techtushuo.com/ts_zuimei",'_blank'
      );
    },
  },
};
</script>
<style lang="less" scoped>
// @import "../../../styles/idphoto/m_app.less";
@import "./index.less";
</style>