<template name="dialog">
  <div class="dialog-wrap">
    <div class="dialog-mask"
         v-show="showDialog"
         @click="closeDialog"></div>
    <div class="marry-dialog"
         v-show="showDialog">
      <div class="dialog-title">
        <span>{{dialogTitle}}</span>
        <!-- <b v-if="showCloseBtn" class="ic-close" @click="closeDialog"></b> -->
      </div>
      <div class="dialog-main"
           v-html="dialogContent"
           :class="{'align-left': isAlignLeft }"></div>
      <div class="dialog-btns"
           v-if="showDoubleBtn">
        <div class="dialog-btn"
             @click="leftBtnFn">{{leftBtnTxt}}</div>
        <div class="dialog-btn light"
             @click="rightBtnFn">{{rightBtnTxt}}</div>
      </div>
      <div class="dialog-btns"
           v-if="showSingleBtn">
        <div class="dialog-btn"
             :class="{'light':showSingleBtn}"
             @click="rightBtnFn">{{singleBtnName}}</div>
      </div>
      <!-- <div class="renew-dialog-singlebtn" v-show="showSingleBtn" @click="makeSure">
				{{singleBtnName}}
			</div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'common-dialog',
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    showCloseBtn: {
      type: Boolean,
      default: false
    },
    showRechargeBtn: {
      type: Boolean,
      default: false
    },
    showDoubleBtn: {
      type: Boolean,
      default: false
    },
    showSingleBtn: {
      type: Boolean,
      default: false
    },
    singleBtnName: {
      type: String,
      default: '知道了'
    },
    dialogTitle: {
      type: String,
      default: '温馨提示'
    },
    dialogContent: {
      type: String,
      default: ''
    },
    isAlignLeft: {
      type: Boolean,
      default: false
    },
    leftBtnTxt: {
      type: String,
      default: '取消'
    },
    rightBtnTxt: {
      type: String,
      default: '确认'
    }
  },
  data () {
    return {
      title: '',
      pathName: '',
    }
  },
  methods: {
    // jumpRecharge(){
    // 	this.closeDialog();
    // 	uni.navigateTo({
    // 		url: '../recharge/recharge'
    // 	});
    // },
    leftBtnFn () {
      this.$emit('leftBtnFn');
    },
    rightBtnFn () {
      this.$emit('rightBtnFn');
    },
    closeDialog () {
      this.$emit('closeDialog');
    }
  }
}
</script>

<style lang="less" scoped>
@import "./dialog.less";
</style>
