/**
 * author by ff
 * date 2020-03-18
 */
import Vue from 'vue'
import App from './App.vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper)

// 引入路由
// import router 的router 一定要小写， 不要写成Router, 否则报 can't match的报错
import router from "./router/router.js"
router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面meta */
    // console.log(to.meta.content)
    if (to.meta.content) {
        let head = document.getElementsByTagName('head');
        let meta = document.createElement('meta');
        document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
        document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
        meta.content = to.meta.content;
        head[0].appendChild(meta)
    }
    // /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    // if (to.meta.title == "图说科技") {
    //     var link = document.querySelector('link[rel*="icon"]') || document.createElement('link');
    //     link.type = 'image/x-icon';
    //     link.rel = 'shortcut icon';
    //     link.href = 'https://oss.puwu.info/web/static/tushuo_logo.png';
    //     document.getElementsByTagName('head')[0].appendChild(link)
    // }
    next()
});
new Vue({
    el: '#app',
    data: {
        positionX: 0,
        positionY: 0,
    },
    router,  // 注入到根实例中
    render: h => h(App),
    // directives: {
    //     drag: {
    //         // 指令的定义
    //         bind: function (el) {
    //             let odiv = el;  //获取当前元素
    //             oDiv.onmousedown = (e) => {
    //                 //算出鼠标相对元素的位置
    //                 let disX = e.clientX - odiv.offsetLeft;
    //                 let disY = e.clientY - odiv.offsetTop;

    //                 document.onmousemove = (e) => {
    //                     //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
    //                     let left = e.clientX - disX;
    //                     let top = e.clientY - disY;

    //                     //绑定元素位置到positionX和positionY上面
    //                     this.positionX = top;
    //                     this.positionY = left;

    //                     //移动当前元素
    //                     odiv.style.left = left + 'px';
    //                     odiv.style.top = top + 'px';
    //                 };
    //                 document.onmouseup = (e) => {
    //                     document.onmousemove = null;
    //                     document.onmouseup = null;
    //                 };
    //             };
    //         }
    //     }
    // }
})


