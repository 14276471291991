<template>
  <div class="photo-pic-top">
    <div class="photo-old" :style="{width: reObj.oldPicWidth}" @click="clickReFn">
      <img :src="reObj.oldpic" :style="{width: reObj.wrapWidth+'px'}" alt />
      <span>修复前</span>
    </div>

    <div class="photo-change pc-show" :style="{left: reObj.btnLeft}" @mousedown="moveReFn"></div>

    <div
      class="photo-change phone-show"
      :style="{left: reObj.btnLeft}"
      :id="reObj.changeId"
      @touchstart="down"
      @touchmove="moveReFnMobile"
      @touchend="end"
    ></div>

    <div class="photo-new" :style="{width: reObj.newPicWidth}" @click="clickReFn">
      <img :src="reObj.newpic" :style="{width: reObj.wrapWidth+'px'}" alt />
      <span>修复后</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "pics-drag",
  props: {
    reObj: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {};
  },
  methods: {
    clickReFn(e) {
      this.$emit("clickReFn", e);
    },
    moveReFn(e) {
      this.$emit("moveReFn", e);
    },
    moveReFnMobile(e) {
      this.$emit("moveReFnMobile", e);
    },
    down(e) {
      this.$emit("down", e, this.reObj.changeId);
    },
    end(e) {
      this.$emit("end", e);
    },
  },
};
</script>
<style lang="less" scoped>
// @import "../../../styles/idphoto/m_app.less";
@import "./ts_rephoto.less";
@import "./ts_rephoto_phone.less";
</style>